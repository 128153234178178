
import FormButton from '@/components/FormButton';
import Alert from '@/components/Alert';

/* USED FOR EITHER ADDING A HASHTAG OR MENTIONING A USER IN A POST/COMMENT */
export default {
  name: 'AddTagButton',
  components: { FormButton, Alert },

  props: {
    alertOpen: {
      default: false,
    },
    type: {
      default: 'THOUGHT',
    },
    textboxValue: {
      default: '',
    },
    textboxDisabled: {
      default: false,
    },
    char: {
      default: '#',
    },
  },

  data() {
    return {
      clicked: false,
    };
  },
  watch: {
  },

  computed: {
    contentType() {
        switch (this.type) {
        case 'POLL': return 'poll';
        default: return 'post';
      }
    },
    isHashtag() {
      return this.char === '#';
    },
    tagType() {
      return this.isHashtag ? 'hashtags' : 'users';
    },
    buttonDisabled() {
      return this.textboxDisabled;
    },
    buttonActive() {
      if (this.buttonDisabled || !this.textboxValue) {
        return false;
      }
      // is typing a hashtag
      return !!this.textboxValue.match(/(#[a-zA-Z0-9]*)$/);
    },
    queryText() {
      let text = '';
      // already in a hashtag
      if ((this.textboxValue.match(/#[^\s]*$/) && this.isHashtag) || (this.textboxValue.match(/@[^\s]*$/) && !this.isHashtag)) {
        return text;
      }

      // character needs a space?
      if (this.textboxValue.match(/\s+$/) || this.textboxValue.match(/^$/)) {
        text = this.char;
      } else {
        text = ` ${this.char}`;
      }

      return text;
    },
    showPrompt() {
      // show for specific content length
      return this.textboxValue.length >= 10 &&
            // dont show if:
            // - content has hashtag
            !this.textboxValue.match(/#\w+/) &&
            !this.textboxValue.match(/#$/) &&
            // - button is disabled
            !this.textboxDisabled &&
            !!this.$refs.button &&
            // - prompt got persisted as disabled
            !this.$store.getters.isHashtagsPromptDisabled &&
            // - button got clicked
            !this.clicked;
    },
    promptPosition() {
      if (this.$refs.button) {
        const button = this.$refs.button;
        return button.offsetTop + button.offsetHeight + 14;
      }
      return 0;
    },
  },

  methods: {
    handleClosePrompt() {
      this.$store.commit('closeHashtagsPrompt');
    },

    handleButtonClick() {
      if (!this.buttonDisabled) {
        this.clicked = true;
        this.$emit('clicked', this.queryText);
      }
    },

    blur(e) {
      this.$emit('blur', e);
    },
  },
};
