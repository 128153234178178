
/* eslint no-console:0 */
// libs
import { log } from '@/data/helpers';

export default {
  name: 'CountDown',
  // components: { },

  // props accepted from parent
  props: [
    'length', // current length of the content
    'max', // maximum length of content
    'warnAt', // show a warning when reached
    'hideUntilWarn', // only show this module when there is a warning
    'limitMsg', // the message to display if at the limit. blank means just show the numbers
    'charOverMsg',
  ],

  // calculated properties
  computed: {
    // how much length is remaining?
    remaining() {
      return this.max - this.length;
    },

    // do we currently need a warning?
    needsWarning() {
      return this.remaining <= this.warnAt;
    },

    // should we be showing the count right now?
    showCount() {
      if (!this.hideUntilWarn) {
        return true;
      }

      return this.needsWarning;
    },

    // is there a final message to display when at the limit?
    hasFinalMessage() {
      return !!this.limitMsg;
    },

    hasCharOverMsg() {
      return !!this.charOverMsg;
    },

    overCount() {
      return Math.abs(this.remaining);
    },
  },

  watch: {
    remaining() {
      if (this.remaining === 0) {
        this.$emit('charLimitReached', true, this.$el);
      } else {
        this.$emit('charLimitReached', false, this.$el);
      }
    },
  },
};
