
/* eslint no-console:0 */
import { isSet, isObject } from '@/data/helpers';

export default {
  name: 'FormButton',
  props: {
    buttonContent: {
      default: '',
    },
    args: {
      default() {
        return {
          type: 'button',
          text: 'Confirm',
          disabled: false,
          classes: '',
        };
      },
    },
  },
  beforeMount() {
    this.args = isSet(this.args) && isObject(this.args) ? this.args : {};
    this.args.value = this.args.value ? this.args.value : '';
  },
  computed: {
    properType() {
      let type = this.args.type;
      if (['button', 'delete', 'cancel'].indexOf(this.args.type) > -1) {
        type = 'button';
      }
      return type;
    },
    isDelete() {
      return this.args.type === 'delete';
    },
    isCancel() {
      return this.args.type === 'cancel';
    },
    isLoadingBtn() {
      return !!this.args.loadMore;
    },
    extraClasses() {
      const classes = {
        'cancel-btn': this.isCancel,
        'delete-btn': this.isDelete,
        'load-more': this.isLoadingBtn,
      };
      classes[this.args.classes] = !!this.args.classes;
      return classes;
    },
  },
  methods: {
    passClick(e) {
      this.$emit('click', e);
    },
  },
};
