

export default {
  name: 'HelperMark',
  props: ['msg'],
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggleHelper(evt) {
      evt.stopPropagation();
      this.open = !this.open;
      return false;
    },
  },
};
