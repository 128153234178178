
/* eslint no-console:0 */
import { mapGetters } from 'vuex';
import extend from 'extend';
import { isSet } from '@/data/helpers';

const validVSnap = ['top', 'bottom', 'center'];
const validHSnap = ['left', 'right', 'center'];
function calcSnap(str) {
  const parts = str.split(/\s+/, 2);
  return {
    vertical: isSet(parts[0]) && validVSnap.indexOf(parts[0]) > -1 ? parts[0] : 'top',
    horizontal: isSet(parts[1]) && validHSnap.indexOf(parts[1]) > -1 ? parts[1] : 'center',
  };
}

export default {
  name: 'Alert',
  props: {
    name: {
      default: '',
    },
    positionArgs: {
      type: Object,
      default() {
        return {
          snapTo: 'top center',
          verticalOffset: 0,
          horizontalOffset: 0,
        };
      },
    },
  },

  computed: {
    ...mapGetters({
      getAlertState: 'getAlertState',
    }),

    isOpen() {
      return this.getAlertState(this.myName);
    },

    myPosition() {
      const data = extend({
        snapTo: 'top center',
        verticalOffset: 0,
        horizontalOffset: 0,
      }, this.positionArgs);
      data.snapTo = calcSnap(data.snapTo);
      return data;
    },

    myName() {
      if (isSet(this.name) && this.name.length) {
        return this.name;
      }

      return this.$vId;
    },

    myShimStyle() {
      const rawPos = this.myPosition;
      let st = '';
      // set vertical styles
      if (rawPos.snapTo.vertical === 'top') {
        st = `top:${rawPos.verticalOffset}px;`;
      } else if (rawPos.snapTo.vertical === 'bottom') {
        st = `bottom:${rawPos.verticalOffset}px;`;
      } else {
        st = `top:${rawPos.verticalOffset}px;bottom:${rawPos.verticalOffset}px;`;
      }

      // horizontal styles
      if (rawPos.snapTo.horizontal === 'left') {
        st = `${st}padding-left:${rawPos.horizontalOffset}px;`;
      } else if (rawPos.snapTo.horizontal === 'right') {
        st = `${st}padding-right:${rawPos.horizontalOffset}px;`;
      } else {
        st = `${st}padding:0 ${rawPos.horizontalOffset}px;`;
      }

      return st;
    },

    myStyle() {
      const rawPos = this.myPosition;
      let st = '';
      // set vertical styles
      if (rawPos.snapTo.vertical === 'top') {
        st = 'top:0;';
      } else if (rawPos.snapTo.vertical === 'bottom') {
        st = 'bottom:0;';
      } else {
        st = 'top:0;bottom:0;';
      }

      return st;
    },
  },

  methods: {
    closeAlert() {
      this.$store.dispatch('closeAlert', this.myName);
      this.$emit('closeAlert', this.myName);
    },
  },
};
